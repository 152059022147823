/* import material-design-icons */
@import '~material-design-icons/iconfont/material-icons.css';
@import './typography.css';

body {
  box-sizing: border-box;
  background-color: #f4f5f6;
  font-family: 'Inter', 'Roboto', 'Arial', 'Times New Roman', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  background-color: #f4f5f6;
}

#clear-prop {
  /* 119px = 48px app header + 71px app footer */
  height: calc(100vh - 119px) !important;
}

body.grabbing *{
  /* fallback for browsers that don't support grab/grabbing */
  cursor: move !important;
  cursor: grabbing !important;
}

.app {
  display: flex;
  height: 100%;
  background-color: #f4f5f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pac-container {
  z-index: 1300
}

.truncated {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
