h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
  margin: 0 0 10px 0;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

h5,
h6 {
  font-weight: 600;
}

h1,
.h1 {
  font-size: 2.25rem;
  line-height: 2.75rem;
}

h2,
.h2 {
  font-size: 1.5rem;
  line-height: 1.875rem;
}

h3,
.h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.25rem;
}

h5,
.h5 {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

h6,
.h6 {
  font-size: 0.75rem;
  line-height: 1rem;
}

p,
div {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal;
  color: #333333;
}

p {
  margin-bottom: 10px;
}

p.small {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

p.large {
  font-size: 1rem;
  line-height: 1.375rem;
}

.sub1 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: normal;
  color: #747678;
}

.sub2 {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: normal;
  color: #747678;
}

.sub3 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal;
  color: #747678;
}

.sub4 {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: normal;
  color: #747678;
}
